@import "./globalColor";

.App {
  text-align: center;
  width: 100%;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: $appHeaderBG;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(0.625rem + 2vmin);
  color: $textColorDark;
}

.App-link {
  color: $appLink;
}

.subTitle {
  color: $subTitle;
}

@keyframes glow-dark {
  0% {
    box-shadow: 0 0 0 $skillsColor; /* Start with no glow */
  }
  7% {
    box-shadow: 0 0 20px $skillsColor; /* Fade out glow within last 2 seconds */
  }
  83% {
    box-shadow: 0 0 20px $skillsColor; /* Start with no glow */
  }
  90% {
    box-shadow: 0px 10px 30px -15px $darkBoxShadow; /* End with no glow */
  }
}

@keyframes glow-light {
  0% {
    box-shadow: 0 0 0 $skillColor; /* Start with no glow */
  }
  5% {
    box-shadow: 0 0 20px $skillColor; /* Fade out glow within last 2 seconds */
  }
  85% {
    box-shadow: 0 0 20px $skillColor; /* Start with no glow */
  }
  90% {
    box-shadow: 0px 10px 30px -15px $lightBoxShadowDark; /* End with no glow */
  }
}

.glowing-dark {
  animation: glow-dark 20s ease-in; /* Total animation duration is 10 seconds */
}

.glowing-light {
  animation: glow-light 20s ease-in; /* Total animation duration is 10 seconds */
}

.card-skills-title {
  color: $skillColor;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin: 15px 0 0 0;
}

.card-skills-title-dark {
  color: $skillColorDark;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin: 15px 0 0 0;
}
