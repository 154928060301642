@import "../../_globalColor";
@import "../../App.scss";

.card-image {
  max-width: 100%;
  height: auto;
  width: 250px;
  border-radius: 15px;
}
.card-title {
  color: $titleColor;
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
  margin: 15px 0 0 0;
}

.card-subtitle {
  color: $cardSubtitle;
  font-size: 17px;
  line-height: 1.5rem;
}

.skill-color {
  color: $skillColor;
  font-weight: bold;
}

.skill-color-dark {
  color: $skillColorDark;
  font-weight: bold;
}

.card-skills-subtitle {
  color: $cardSubtitle;
  font-size: 17px;
  line-height: 1.5rem;
  text-align: left;
}

.project-card {
  // display: flex;
  // flex-direction: column;
  background-color: $lightBackground2;
  padding-top: 2rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  border-radius: 10px;
  border: 1px solid $lightBorder1;
  justify-content: center;
}
.project-card-light {
  box-shadow: $lightBoxShadowDark 0px 10px 30px -15px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.project-card-dark {
  box-shadow: 0px 10px 30px -15px $darkBoxShadow;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.25s ease;
}

.project-detail {
  text-align: center;
}
.project-card-light:hover {
  box-shadow: $lightBoxShadowDark 0px 20px 30px -10px;
}
.project-card-dark:hover {
  box-shadow: 0px 20px 30px -10px $darkBoxShadow;
}
.project-image {
  position: relative;
  height: 250px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.projects-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 1rem 1rem;
}

.project-card-footer span.project-tag {
  background: $buttonColor;
  color: $lightBackground3;
  vertical-align: middle;
  align-items: center;
  border-radius: 8px;
  display: inline-flex;
  font-size: 0.9rem;
  height: 2.5em;
  justify-content: center;
  white-space: nowrap;
  line-height: 1.5;
  margin: 0 0.5rem 0.5rem 0;
  padding: 0 0.75em;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.project-card-footer span.project-tag:hover {
  background-color: $buttonHover;
}

@media (max-width: 768px) {
  .project-subtitle {
    font-size: 16px;
    text-align: center;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.popup.fade-in {
  animation: fadeIn 1s forwards; /* Fade-in effect over 1s */
}

.popup.fade-out {
  animation: fadeOut 0.5s forwards; /* Fade-out effect over 0.5s */
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #2f2f2f;
  border-radius: 20px;
  padding-top: 20px; /* Adds space at the top */
  padding-left: 20px; /* Adds space on the left */
  padding-right: 20px; /* Adds space on the right */
  padding-bottom: 10px; /* Adds space at the bottom */
  z-index: 999; // Ensure it's on top of other elements
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 80%; // Adjust according to your design
  max-height: 80%; // Adjust according to your design
  overflow-y: hidden;
  opacity: 0; /* Start hidden */
}

.dark {
  background-color: $galleryBGDark;
}

.light {
  background-color: $galleryBGLight;
}

.popup.visible {
  opacity: 1; /* Show when visible class is added */
}

.horizontal-scroll {
  display: flex; /* Use flexbox to make items stay in a row */
  overflow-x: auto; /* Enable horizontal scrollbar */
  white-space: nowrap; /* Prevent wrapping */
}

.horizontal-scroll .gallery-item:not(:last-child) a {
  margin-right: 20px; /* Add some space between images */
}

.vertical-scroll {
  overflow-y: auto; /* Enable horizontal scrollbar */
  width: 80vw;
  // -ms-overflow-style: none;
  // scrollbar-width: none;
}

// .vertical-scroll::-webkit-scrollbar {
//   display: none;
// }

.vertical-scroll a {
  height: 100%;
  padding: 0px;
  margin: 0px;
}

.gallery-item {
  padding-bottom: 15px;
  user-select: none;
}

.gallery-img {
  max-height: 70vh; /* Ensures the image does not exceed 70% of the viewport height */
  width: auto; /* Allows the image to maintain its aspect ratio */
  object-fit: contain; /* Ensures the image keeps its aspect ratio */
  border-radius: 10px;
  user-select: none;
}

#gallery img {
  pointer-events: none; /* Disable pointer events on the image */
}

#gallery {
  cursor: pointer; /* Change cursor to pointer when hovering */
}

.blur-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Blur effect */
  z-index: 998; /* Ensure it sits behind the popup but above other content */
  opacity: 0; /* Start hidden */
  transition: opacity 0.5s; /* Fade effect */
}

.blur-overlay.visible {
  opacity: 1; /* Show when visible class is added */
}

.close-btn-dark {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #db5856;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  border-color: transparent;
  cursor: pointer;
  // box-shadow: 0 0 10px rgba(171, 171, 171, 0.1);
  z-index: 1000; /* Ensure it shows on top of everything */
  opacity: 0; /* Start hidden */
  transition: opacity 0.5s; /* Fade effect */
}

.close-btn-dark.visible {
  opacity: 1;
}

.caption {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 20px;
  color: #fff;
  user-select: none;
}

.darkc {
  color: #fff;
}

.lightc {
  color: #000;
}

.tip-light {
  display: none;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ccc;
  color: $textColor;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(58, 58, 58, 0.1);
  z-index: 2000; /* Ensure it shows on top of everything */
  animation: fadeInOut 5s ease-in-out;
}

.tip-dark {
  display: none;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: $textColorDark;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(171, 171, 171, 0.1);
  z-index: 2000; /* Ensure it shows on top of everything */
  animation: fadeInOut 5s ease-in-out;
}

.tip-dark.show {
  display: block;
}

.tip-light.show {
  display: block;
}

.arrow-icon {
  position: relative;
  top: 1px; /* Adjust this value as needed to align the icon */
}

/* Media query to switch to vertical scrolling on small screens */
@media (max-width: 768px) {
  #gallery {
    width: 100%;
  }

  .gallery-item {
    max-width: 100%;
    padding-bottom: 30px;
  }
  .gallery-item:last-child {
    padding-bottom: 0px;
    // margin-bottom: -10px;
    // max-width: 100%;
  }

  .gallery-img {
    max-height: auto;
    height: auto;
    max-width: 100%;
  }

  .popup {
    padding: 0px;
    // padding-bottom: 0px;
    // padding-right: 10px;
    // border-top-right-radius: 0px;
    // border-bottom-right-radius: 0px;
    // border-radius: 0px;
    box-sizing: border-box;
    // -ms-overflow-style: none;
    // scrollbar-width: none;
  }

  .dark {
    border-top: 10px solid $galleryBGDark; /* Adjust the color as needed */
    border-left: 10px solid $galleryBGDark; /* Adjust the color as needed */
    border-right: 10px solid $galleryBGDark; /* Adjust the color as needed */
    border-bottom: 10px solid $galleryBGDark; /* Adjust the color as needed */
  }

  .light {
    border-top: 10px solid $galleryBGLight; /* Adjust the color as needed */
    border-left: 10px solid $galleryBGLight; /* Adjust the color as needed */
    border-right: 10px solid $galleryBGLight; /* Adjust the color as needed */
    border-bottom: 10px solid $galleryBGLight; /* Adjust the color as needed */
  }

  .tip-dark {
    top: 10px;
  }
  .tip-light {
    top: 10px;
  }

  .caption {
    font-size: medium;
    margin-bottom: 0px;
  }
}
