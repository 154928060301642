@import "../../_globalColor";

.meter {
  height: 20px;
  position: relative;
  background: $progressBarBackground;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
}
.meter > span {
  display: block;
  height: 100%;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  position: relative;
  overflow: hidden;
}
.skill {
  line-height: 3.5vw;
}
.skill-bars {
  font-size: 28px;
  width: 40%;
}
.skills-container {
  display: flex;
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 2rem;
}
.skills-container > * {
  flex: 1;
  margin-bottom: 30px;
}
.skills-image > img {
  margin-left: 80px;
  max-width: 90%;
  height: auto;
}

/* Media Query */

@media (max-width: 1456px) {
  .skills-bar {
    line-height: 4rem;
  }
  .skills-image {
    display: none;
    order: 2;
  }
}

@media (max-width: 768px) {
  .skills-container {
    flex-direction: column;
  }
  .skills-bar {
    line-height: 3rem;
    margin: 20px;
  }
  .skills-image {
    display: none;
    order: 2;
  }
}

.small-space {
  margin-right: 4px; /* Adjust the value to your desired space */
}

.info-box-light {
  background-color: rgba(165, 135, 202, 0.6); // 54, 206, 220, 0.35
  border-radius: 10px; /* Rounded corners */
  padding: 0px 20px 0px 20px;
  display: inline-block; /* Inline-block to fit the content */
  flex-wrap: wrap;
  color: $textColor;
  align-items: center;
}

.info-box-dark {
  background-color: rgba(165, 135, 202, 0.7); // rgba(165, 135, 202, 0.7)
  border-radius: 10px; /* Rounded corners */
  padding: 0px 20px 0px 20px;
  display: inline-flex; /* Inline-block to fit the content */
  color: $textColorDark;
  flex-wrap: wrap;
  align-items: center;
}

pre {
  line-height: 1.75; /* Adjust the value as needed */
  white-space: pre-wrap;
  display: inline-block;
}

.circle-span {
  display: inline-block;
  white-space: nowrap; /* Prevent wrapping */
  white-space: inherit;
}

@media (max-width: 1456px) {
  .info-icon {
    flex: 0 0 auto; /* This keeps the first column thin */
    margin-right: 25px; /* Add some space between icon and content */
    margin-left: 2px;
    margin-bottom: 3px;
  }
}

@media (min-width: 1456px) {
  .info-icon {
    flex: 0 0 auto; /* This keeps the first column thin */
    margin-right: 25px; /* Add some space between icon and content */
    margin-left: 2px;
    margin-bottom: 1px;
  }
}

.info-content {
  flex: 1 1 auto; /* This makes the second column take remaining space */
  margin-bottom: -5px;
  margin-top: -5px;
}

@media (max-width: 1456px) {
  .spacing {
    margin: 0px;
  }
}

@media (min-width: 1456px) {
  .spacing {
    margin: 10px;
  }
}
