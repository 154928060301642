@import "../../_globalColor";

.skills-main-div {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.skills-text-div {
  margin-left: 50px;
}

.skills-main-div > * {
  flex: 1;
  margin-bottom: 30px;
}

.skills-image-div > img {
  max-width: 100%;
  height: auto;
}

.skills-heading {
  font-size: 56px;
  font-weight: 400;
}
.subTitle {
  color: $subTitle;
}

/* Media Query */
@media (max-width: 1380px) {
  .skills-heading {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .skills-heading {
    font-size: 30px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 16px;
    line-height: normal;
  }
  .skills-main-div {
    flex-direction: column;
  }
  .skills-text-div {
    margin-left: 0px;
    margin: 20px;
  }
  .skills-text {
    font-size: 16px;
  }
  .skills-text-subtitle {
    font-size: 16px;
    text-align: center;
  }
  .skills-image-div {
    /* display: none; */
    order: 2;
  }
}

.popup-light {
  display: none;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #999;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(58, 58, 58, 0.1);
  z-index: 1000; /* Ensure it shows on top of everything */
  animation: fadeInOut 5s ease-in-out;
}

.popup-dark {
  display: none;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(171, 171, 171, 0.1);
  z-index: 1000; /* Ensure it shows on top of everything */
  animation: fadeInOut 5s ease-in-out;
}

.popup-dark.show {
  display: block;
}

.popup-light.show {
  display: block;
}

@keyframes fadeInOut {
  0% {
    opacity: 0; /* Start hidden */
  }
  15% {
    opacity: 0; /* Fade in */
  }
  35% {
    opacity: 1; /* Fade out */
  }
  75% {
    opacity: 1; /* Fade out */
  }
  100% {
    opacity: 0; /* Fade out */
  }
}
