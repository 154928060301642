@import "../../_globalColor";

.load-icon {
  display: none;
  position: fixed;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  border-color: transparent;
  z-index: 999; /* Ensure it shows on top of everything */
}

.light-load {
  color: $textColorDark;
}

.dark-load {
  color: $textColorDark;
}

.load-icon.show {
  display: block;
}
