@import "../../_globalColor";
@import "../../App.scss";

.dev-icons {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
}

.software-skill-inline {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
}

.software-skill-inline > i {
  color: $subTitle;
}

.software-skill-inline > i:hover {
  color: $skillsColor;
}

.software-skill-inline.light-mode > i:hover {
  color: $skillsColorLight;
}

.software-skill-inline > p {
  color: $subTitle;
  font-size: 10px;
}

.software-skill-inline > i:hover ~ p {
  color: $skillsColor;
}

.software-skill-inline.light-mode > i:hover ~ p {
  color: $skillsColorLight;
}

#skillButton {
  visibility: hidden;
  position: fixed;
  bottom: 20px;
  right: 90px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: $buttonColor;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 15px;
  font-size: 25px;
}

#skillButton:hover {
  background-color: $topButtonHover;
  transition: all ease-in-out 0.2s;
}
